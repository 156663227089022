<template>
  <div class="page-main-box">
    <!-- <a-button @click="login">登录</a-button> -->
    <img src="@/assets/image/home.png" class="bg" />
  </div>
</template>

<script>
import { modalLoginApi } from "@/apis/login";
export default {
  name: "Home",
  components: {},
  setup() {
    // const loginApi = modalLoginApi();
    const login = async () => {
      let res = await modalLoginApi();
      console.log(res);
    };
    return {
      login
    };
  }
};
</script>
<style lang="less" scoped>
.page-main-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg {
  height: 170px;
  width: 200px;
}
</style>
