// import { get } from "@/utils/http";

// export const portalLoginApi = params => {
//   get("/portal/login", params);
// };

import { buildApi2 } from "@/utils/ajax";
import { AjaxMethods } from "@wlhy-web-lib/ajax";

const basicApi = buildApi2("/portal/ops");

export const portalLoginApi = basicApi({
  url: "/login",
  method: AjaxMethods.GET,
  withCredentials: true
});

export const modalLoginApi = basicApi({
  url: "/dev/login",
  method: AjaxMethods.GET,
  withCredentials: true
});
